import { Row, Col } from "react-bootstrap"
import React from 'react'
import ZigzagImage from "./zigzag-image"

const HomepageRows = ({ rows }) => {

  return rows.map((row, i) => (
    <Row
      className={`align-items-center my-4 ${i % 2 === 0 ? "flex-row-reverse" : ""
        }`}
    >
      <Col lg="4" className={"mb-4 mb-md-0"}>
        <ZigzagImage fluid={row.image.childImageSharp.fluid} alt={row.title} />
      </Col>
      <Col lg="8">
        <h5>{row.title}</h5>
        <p>{row.description}</p>
        <p className="text-md-left text-center">
          <a
            href={row.buttonURL}
            target="_blank"
            rel="noopener noreferrer"
            className={"btn text-uppercase btn-primary btn-rounded fw-bold"}
          >
            {row.buttonText}
          </a>
        </p>
      </Col>
    </Row>
  ))
}

export default HomepageRows
